<template>
  <el-config-provider :locale="locate">
    <router-view></router-view>
  </el-config-provider>
</template>
<script>


import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

export default {
  //element-plus 报错 ResizeObserver loop limit exceeded 解决
  created() {
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      }
    }

    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    }
  },
  setup() {
    return {
      locate: zhCn
    }
  }
}
</script>
<style>

</style>
