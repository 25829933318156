import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import {ElNotification} from "element-plus";
import {Authorization} from "@/assets/js/authorization";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    redirect: '/index',
    component: () => import('../components/Home.vue'),
    meta: {
      loginRequire: true
    },
    children: [
      {
        path: '/index',
        name: '首页',
        hidden: true,
        component: () => import('../views/Index.vue'),
      }, {
        path: '/menu',
        name: '菜单管理',
        hidden: true,
        component: () => import('../views/Menu.vue'),
      }
      , {
        path: '/resource',
        name: '资源管理',
        hidden: true,
        component: () => import('../views/Resource.vue'),
      }
      , {
        path: '/role',
        name: '角色管理',
        hidden: true,
        component: () => import('../views/Role.vue'),
      }
      , {
        path: '/users',
        name: '用户列表',
        hidden: true,
        component: () => import('../views/UserList.vue'),
      }, {
        path: '/online',
        name: '在线用户',
        hidden: true,
        component: () => import('../views/OnlineUser.vue'),
      }, {
        path: '/blog-menu',
        name: '博客菜单管理',
        hidden: true,
        component: () => import('../views/BlogMenu.vue'),
      }, {
        path: '/blog-resource',
        name: '博客资源管理',
        hidden: true,
        component: () => import('../views/BlogResource.vue'),
      }, {
        path: '/tag',
        name: '标签管理',
        hidden: true,
        component: () => import('../views/Tag.vue'),
      }, {
        path: '/category',
        name: '分类管理',
        hidden: true,
        component: () => import('../views/Category.vue'),
      }, {
        path: '/articles',
        name: '发布文章',
        hidden: true,
        component: () => import('../views/Article.vue'),
      }, {
        path: '/article-list',
        name: '文章列表',
        hidden: true,
        component: () => import('../views/ArticleList.vue'),
      }
      , {
        path: '/talks',
        name: '说说列表',
        hidden: true,
        component: () => import('../views/Talk.vue'),
      }
      , {
        path: '/albums',
        name: '相册列表',
        hidden: true,
        component: () => import('../views/Album.vue'),
      }, {
        path: '/photos',
        name: '相册管理',
        hidden: true,
        component: () => import('../views/Photo.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由登录拦截
router.beforeEach((to, from, next) => {
  // 要不要对meta.loginRequire属性做监控拦截
  if (to.matched.some(function (item) {
    console.log(item, "是否需要登录校验：", item.meta.loginRequire || false);
    return item.meta.loginRequire
  })) {
    const userInfo = store.state.userInfo;
    console.log("页面登录校验开始：", userInfo);
    if (!userInfo.token) {
      console.log("用户未登录或登录超时！");
      ElNotification({
        title: '出错啦',
        message: '未登录或登录超时',
        type: 'error',
      })
      next('/login');
    } else {
      if (Authorization.hasMenuRouter(to.path)) {
        next();
      } else {
        console.log("您没有对应的权限！");
        ElNotification({
          title: '出错啦',
          message: '您没有对应的权限',
          type: 'error',
        })
        console.error("跳转到主页");
        next('/');
      }
    }
  } else {
    next();
  }
});


export default router

