import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus, {ElNotification} from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import '@/assets/css/iconfont.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from "axios";
import './assets/css/main-container.css'
import './assets/js/enums';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(mavonEditor);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app');

/**
 * axios拦截器
 */
axios.interceptors.request.use(function (config) {
  console.log('请求参数：', config);
  const token = store.state.userInfo.token;
  if(token){
    config.headers.token = token;
    console.log("请求headers增加token：",token)
  }
  return config;
}, error => {
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
  console.log('返回结果：', response);
  return response;
}, error => {
  console.log('返回错误：', error);
  
  const response = error.response;
  const status = response.status;
  if(status === 401){
    //判断状态码为401 跳转到登录页
    console.log("未登录或登陆超时，跳到登录页")
    window.SessionStorage.set("USER",{});
    store.commit("userInfo",{})
    ElNotification({
      title: '出错啦',
      message: '未登录或登录超时',
      type: 'error',
    })
    router.push("/login")
  }
  else if(status === 403){
    console.log("您的权限不足")
    window.SessionStorage.set("USER",{});
    store.commit("userInfo",{})
    ElNotification({
      title: '出错啦',
      message: '您的权限不足',
      type: 'error',
    })
    router.push("/login")
  }
  
  return Promise.reject(error);
});

axios.defaults.baseURL = process.env.VUE_APP_SERVER;
console.log('环境：', process.env.NODE_ENV);
console.log('服务端：', process.env.VUE_APP_SERVER);
