import { createStore } from 'vuex'

const USER = "USER";
const MENU = "MENU";

export default createStore({
  state: {
    collapse: false,
    tabList: [{ name: '首页', path: '/index' }],
    userInfo: window.SessionStorage.get(USER) || {},
    userMenus: window.SessionStorage.get(MENU) || [],
  },
  getters: {
  },
  mutations: {
    saveTab(state, tab) {
      if (state.tabList.findIndex((item) => item.path === tab.path) === -1) {
        state.tabList.push({ name: tab.name, path: tab.path })
      }
    },
    removeTab(state, tab) {
      var index = state.tabList.findIndex((item) => item.name === tab.name)
      state.tabList.splice(index, 1)
    },
    resetTab(state) {
      state.tabList = [{ name: '首页', path: '/index' }]
    },
    login(state, user) {
      sessionStorage.setItem('token', user.token)
      state.userInfo = user
      window.SessionStorage.set(USER,user)
    },
    logout(state){
      sessionStorage.removeItem('token');
      state.userInfo = {};
      window.SessionStorage.remove(USER);
    },
    saveUserMenus(state, userMenus) {
      state.userMenus = userMenus
      window.SessionStorage.set(MENU,userMenus)
    },
    trigger(state) {
      state.collapse = !state.collapse
    },
  },
  actions: {
  },
  modules: {
  }
})
